<template>
	<div class="ticker-wrapper">

		<!-- Last Investor -->
		<transition-group tag="div" name="list" class="list-wrap">
			<InvestmentBox v-for="(investment) in investments.items" :key="investment.id" :investment="investment" class="list-item"/>
		</transition-group>

		<!-- Last Lead -->
		<div :class="{'lead-details': true, 'loading': !lastLead.loaded && !lastLead.confirmed, 'confirmed': lastLead.confirmed, 'updating': lastLead.updating}">
			<span>New Lead!</span>
			<h1>{{ lastLead.firstName }} {{ lastLead.lastName }}</h1>
<!--			<h3>{{ lastLead.email }}</h3>-->
		</div>

		<!-- Offer Stats -->
		<OfferStats :offer="offer"/>

	</div>
</template>

<script>
import Pusher from "pusher-js";
import repository from "@/repository/repository";
import OfferStats from "@/OfferStats";
import InvestmentBox from "@/InvestmentBox";

export default {
    name: "Ticker",
    components: {InvestmentBox, OfferStats},
    data: function () {
        return {
            offer: {
                targetAmount: null,
                fundedAmount: null,
                totalInvestors: null,
                loaded: false,
                error: false,
				updating: false,
            },
            investments: {
                updating: true,
                items: [],
			},
            lastLead: {
                firstName: "",
                lastName: "",
                email: "",
                phone: "",
                loaded: false,
                updating: false,
                confirmed: false,
            }
        }
	},
    created() {
        this.loadOfferDetails();
        this.loadLastInvestment();
        this.startPusher();
    },
    methods: {
        startPusher() {
            let app = this;

            // Enable pusher logging - don't include this in production
            // Pusher.logToConsole = true;

            // Start Pusher
            let pusher = new Pusher(process.env.VUE_APP_PUSHER_ID, {
                cluster: process.env.VUE_APP_PUSHER_CLUSTER
            });

            // Subscribe to Channel (different for each environment)
            let channel = pusher.subscribe(process.env.VUE_APP_PUSHER_CHANNEL);

            // On New Investment
            channel.bind(process.env.VUE_APP_PUSHER_NEW_INVESTMENT_EVENT, function(data) {
                console.log("New investor event", data);
                app.investments.updating = true;
                app.investments.items.forEach(investment => investment.main = false);
                app.investments.items.push({
                    id: data.id,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    state: data.state,
                    email: data.email,
                    amount: data.amount,
                    updatedAt: data.updatedAt,
					main: true,
                    loaded: true
				})
                app.investments.updating = false;
                app.investments.items.splice(0, 1);
                app.playNewInvestmentSound();

                // If we match the lead with the new investor, move the lead box effect
                if(data.email === app.lastLead.email) {
                    app.lastLead.confirmed = true;
                    setTimeout(function () {
                        app.lastLead.loaded = false;
                        app.lastLead.confirmed = false;
                    }, 500);
                } else {
                    app.lastLead.loaded = false;
				}

                // Reload offer stats
                app.loadOfferDetails(true);
            });

            // On New Lead
            channel.bind(process.env.VUE_APP_PUSHER_NEW_LEAD_EVENT, function (data) {
                console.log("New lead event", data);
                app.lastLead.updating = true;
                app.lastLead.firstName = data.firstName;
                app.lastLead.lastName = data.lastName;
                app.lastLead.email = data.email;
                app.lastLead.phone = data.phone;
                app.lastLead.loaded = true;
                setTimeout(function () {
                    app.lastLead.updating = false;
                    app.playNewLeadSound();
                }, 200);
            })
        },
        loadLastInvestment() {
            let app = this;
            repository.get("/last-investment")
                .then(response => {
                    app.investments.updating = true;
                    response.data.reverse().forEach(data => {
                        app.investments.items.push({
                            id: data.id,
                            firstName: data.firstName,
                            lastName: data.lastName,
                            state: data.state,
                            email: data.email,
                            amount: data.amount,
                            updatedAt: data.updatedAt,
                            main: false,
                            loaded: true
                        })
					})
                    app.investments.items[5].main = true;
                    setTimeout(function () {
                        app.investments.updating = false;
                        app.lastLead.loaded = false;
                        app.playNewInvestmentSound();
                    }, 200);
                })
                .catch(() => {
                    this.lastInvestment.updating =  false;
                    this.lastInvestment.loaded = false;
                });
        },
        loadOfferDetails(updating = false) {
            let app = this;
            repository.get("/offer")
                .then(response => {
                    if(response.data.offer !== null) {
                        if(updating) app.offer.updating = true;
                        app.offer.targetAmount = response.data.offer.targetAmount;
                        app.offer.fundedAmount = response.data.offer.fundedAmount;
                        app.offer.totalInvestors = response.data.offer.totalInvestors;
                        app.offer.loaded = true;
                        app.offer.error =  false;
                        setTimeout(function () {
                            if(updating) app.offer.updating = false;
						}, 1500);
                    } else {
                        app.offer.error =  true;
                        app.offer.loaded = true;
                    }
                })
                .catch(() => {
                    app.offer.error =  true;
                    app.offer.loaded = true;
                });
        },
        playNewInvestmentSound() {
            let audio = new Audio(require('./assets/new-investment.mp3'));
            audio.play().catch(() => {
                console.log("The user must interact with the page in order to play sounds.");
			});
        },
        playNewLeadSound() {
            let audio = new Audio(require('./assets/new-lead.mp3'));
            audio.play().catch(() => {
                console.log("The user must interact with the page in order to play sounds.");
            });
        },
    },
}
</script>

<style lang="scss">

// Investors List
.list-wrap {
	position: relative;
	transition: 0.5s ease all;
	display: flex;
	flex-direction: column-reverse;
	padding: 1.5rem 0;
	z-index: 3;
}
.list-item {
}
.list-enter-active, .list-leave-active {
	transition: all 1s;
}
.list-enter, .list-leave-to {
	opacity: 0;
	transform: translateY(30px);
}


// Lead Details
.lead-details {
	position: fixed;
	top: 3rem;
	right: 3rem;
	width: 20rem;
	background: #fff;
	z-index: 2;
	border-radius: 0.5rem;
	padding: 1rem;
	transition: none;

	@media (max-width: 767px) {
		padding: 0.5rem 1rem;
		top: auto;
		bottom: 12rem;
		left: 2.5rem;
		right: 2.5rem;
		width: calc(100% - 5rem);
		z-index: 5;
	}

	span {
		display: block;
		color: #fff;
		background: #F37021;
		padding: 0.25rem 0.5rem;
		border-radius: 0.5rem;
		font-weight: bold;
		text-transform: uppercase;
		font-size: 0.9rem;
		position: absolute;
		top: -0.75rem;
		left: 1rem;
		line-height: 1;

		@media (max-width: 767px) {
			font-size: 1rem;
			top: 0;
			bottom: 0;
			right: 1rem;
			margin: auto;
			height: 1.5rem;
			line-height: 1.5rem;
			padding: 0 0.75rem;
			left: auto;
		}
	}

	h1 {
		font-weight: bold;
		color: #16193A;
		font-size: 1.25rem;
		margin: 0;
		display: block;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;

		@media (max-width: 767px) {
			font-size: 1.5rem;
		}
	}

	h3 {
		font-size: 0.9rem;
		color: rgba(#16193A, 0.75);
		margin: 0;
	}

	&.updating {
		transition: none;
		transform: translateY(-5rem) scale(0.95);
		opacity: 0;
	}

	&.loading {
		opacity: 0;
		transition: 0.45s ease all;
	}

	&.confirmed {
		transform: translateY(6.75rem);
		width: calc(100% - 6rem);
		transition: 0.45s ease all;
		z-index: 2;

		span, h3 {
			opacity: 0;
		}
	}
}

</style>