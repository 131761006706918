<template>
	<div class="row">
		<div class="col-md-12 mx-auto">
			<div :class="{'current-funding-details': true, 'updating': offer.updating}">
				<div v-if="offer.loaded && !offer.error">
					<div class="funding-progress">
						<div class="progress">
							<div class="progress-bar progress-bar-striped" :style="{'width': offerCompletedPercentage + '%'}"></div>
						</div>
						<div class="progress-labels">
							<span>$0</span>
							<span>${{ offer.targetAmount.toLocaleString() }}</span>
						</div>
					</div>
					<div class="funding-details">
						<div class="details-item">
							<img src="./assets/icons/performance.svg" alt="Performance">
							<strong>${{ offer.fundedAmount.toLocaleString() }}</strong>
							<p>Funded Amount</p>
						</div>
						<div class="details-item">
							<img src="./assets/icons/users.svg" alt="Investors">
							<strong>{{ offer.totalInvestors }}</strong>
							<p>Total Investors</p>
						</div>
						<div class="details-item">
							<img src="./assets/icons/pie-chart.svg" alt="Chart">
							<strong>{{ offerCompletedPercentage }}%</strong>
							<p>Funded Percentage</p>
						</div>
					</div>
				</div>
				<div v-if="offer.loaded && offer.error" class="fund-error mt-3">
					<img src="./assets/icons/cancel.png" alt="Cancel" class="error-icon">
					<p>An error happened while loading the current fund details.</p>
				</div>
				<div v-if="!offer.loaded" class="text-center">
					<div class="spinner-border text-white opacity-25 mt-3"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
    name: "OfferStats",
    props: ['offer'],
    computed: {
        offerCompletedPercentage() {
            if(!this.offer.loaded) return 0;
            return ((100 / this.offer.targetAmount) * this.offer.fundedAmount).toFixed(0);
        },
    },
}
</script>

<style lang="scss">

// Current Fund
.current-funding-details {
	display: block;
	position: fixed;
	bottom: 3rem;
	left: 3rem;
	right: 3rem;
	height: auto;
	padding: 3rem;
	border-radius: 0.5rem;
	background: #16193A;
	z-index: 4;

	@media (max-width: 767px) {
		width: 100%;
		left: 0;
		right: 0;
		bottom: 0;
		padding: 2rem;
	}

	.fund-error {
		display: flex;
		align-items: center;

		img.error-icon {
			width: 2rem;
			height: 2rem;
			margin-right: 0.5rem;
		}

		p {
			margin: 0;
			color: #e81e1e;
			font-weight: bold;
		}
	}

	.funding-progress {
		position: relative;

		.progress {
			border-radius: 3rem;
			height: 1.5rem;
			background: #fff;
			overflow: visible;

			.progress-bar {
				border-radius: 3rem;
				background-color: #F37021;
				box-shadow: rgba(#F37021, 0.75) 0 0 2.5rem;
				animation: updating-shadow 1s infinite;
				transition: 0.5s ease all;
			}
		}

		.progress-labels {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-top: 0.5rem;

			span {
				font-size: 0.9rem;
				color: #fff;
				font-weight: 700;
			}
		}
	}

	.funding-details {
		display: flex;
		align-items: flex-start;
		padding-top: 1.5rem;

		.details-item {
			display: block;
			width: 30%;
			padding-left: 3.5rem;
			position: relative;

			@media (max-width: 767px) {
				padding-left: 0;
			}

			&:first-child {
				width: 40%;
			}

			img {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				width: 2.5rem;
				height: 2.5rem;
				object-fit: contain;
				margin: auto;
				filter: brightness(0) invert(1);

				@media (max-width: 767px) {
					display: none;
				}
			}

			strong {
				display: block;
				font-size: 2rem;
				color: #fff;
				font-weight: 700;
				line-height: 1;
				transition: 0.25s ease all;

				@media (max-width: 767px) {
					font-size: 1.5rem;
				}
			}

			p {
				display: block;
				color: #fff;
				font-size: 0.9rem;
				margin: 0;
			}
		}
	}

	&.updating {
		.funding-progress {
			.progress {
				.progress-bar {
					transform-origin: center;
					transform: matrix(1, 0, 0, 1.5, 0, 0);
					animation: updating-shadow 1s infinite, progress-bar-stripes 0.5s infinite;
				}
			}
		}

		.funding-details {
			.details-item {
				animation: updating-size 1.5s ease;

				strong {
					animation: updating-color 1.5s ease;
				}
			}
		}
	}

	@keyframes progress-bar-stripes {
		from { background-position: 2.5rem 0; }
		to { background-position: 0 0; }
	}

	@keyframes updating-size {
		0% {
			transform-origin: center left;
			transform: scale(1);
		}
		50% {
			transform-origin: center left;
			transform: scale(1.5);
		}
		100% {
			transform-origin: center left;
			transform: scale(1);
		}
	}

	@keyframes updating-color {
		0% {
			color: #fff;
		}
		50% {
			color: #F37021;
		}
		100% {
			color: #fff;
		}
	}

	@keyframes updating-shadow {
		0% {
			box-shadow: rgba(#F37021, 0.75) 0 0 2.5rem;
		}
		50% {
			box-shadow: rgba(#F37021, 0.75) 0 0 1.5rem;
		}
		100% {
			box-shadow: rgba(#F37021, 0.75) 0 0 2.5rem;
		}
	}
}
</style>