<template>
	<div id="app">
		<div class="page-wrapper">
			<div class="custom-container">

				<!-- Logo -->
				<img src="./assets/logo.svg" alt="Reiturn by Birgo" class="logo d-block">

				<!-- Full Screen -->
				<button :class="{'btn btn-icon btn-fullscreen': true, 'hidden': fullScreenButtonHidden}" type="button" @click.prevent="toggleFullscreen">
					<img src="./assets/icons/full-screen.svg" alt="Full Screen" v-if="!fullScreen"/>
					<img src="./assets/icons/close.svg" alt="Exit Full Screen" v-if="fullScreen"/>
				</button>

				<!-- Password -->
				<div class="row" v-if="!authenticated">
					<div class="col-lg-6 col-xl-4">
						<form @submit.prevent="checkPassword" class="password-field">
							<label for="password">Please enter your password to continue</label>
							<div class="d-flex">
								<input type="password" class="form-control" id="password" v-model="password">
								<button type="submit" class="btn btn-primary" :disabled="loading">
									<span class="spinner-border" v-if="loading"></span>
									<span v-if="!loading">Login</span>
								</button>
							</div>
							<p class="text-danger small fw-bolder mt-3" v-if="error">Invalid password.</p>
						</form>
					</div>
				</div>

				<!-- Ticker -->
				<Ticker v-if="authenticated"/>

			</div>
		</div>
	</div>
</template>

<script>

import repository from "@/repository/repository";
import Ticker from "@/Ticker";

export default {
    name: 'App',
    components: {
        Ticker
    },
    data: function () {
        return {
            fullScreen: false,
            fullScreenButtonHidden: true,
            fullScreenButtonHideTimeout: null,
            authenticated: false,
            password: null,
            loading: false,
            error: false,
        }
    },
	created() {
        // Check if password provided in URL
        if(this.$route.query.pw) {
            repository.post("/auth", {
                password: this.$route.query.pw,
            })
                .then(() => {
                    this.authenticated = true;
                    this.error = false;
                })
                .catch(() => {
                    this.authenticated = false;
                    this.error = true;
                })
		}

        // Show & hide full screen button
        let app = this;
        document.addEventListener("mousemove", function () {
            console.log("mousemove()");
            app.fullScreenButtonHidden = false;
            app.hideButton();
		})
    },
    methods: {
        checkPassword() {
            repository.post("/auth", {
                password: this.password,
            })
                .then(() => {
                    this.authenticated = true;
                    this.error = false;
                })
                .catch(() => {
                    this.authenticated = false;
                    this.error = true;
                })
        },
        toggleFullscreen() {
            if (!document.fullscreenElement) {
                document.documentElement.requestFullscreen();
                this.fullScreen = true;
            } else if (document.exitFullscreen) {
                document.exitFullscreen();
                this.fullScreen = false;
            }
        },
		hideButton() {
            console.log("hideButton()");
            let app = this;
            if(this.fullScreenButtonHideTimeout) clearTimeout(this.fullScreenButtonHideTimeout);
            this.fullScreenButtonHideTimeout = setTimeout(function () {
                console.log("setTimeoutExecuted()");
                app.fullScreenButtonHidden = true;
            }, 2000);
		}

    },
}
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@400;500;600;700&display=swap");

// Standard
html, body {
	font-family: "Red Hat Display", sans-serif;
	color: #16193A;
	font-size: calc((0.5vw + 0.4vh) * 1.25);

	@media (min-width: 768px) and (max-width: 991.99px) {
		font-size: 12px;
	}

	@media (min-width: 992px) and (max-width: 1199.99px) {
		font-size: 14px;
	}

	@media (max-width: 767.99px) {
		font-size: 10px;
	}
}

// Button Full Screen
.btn-fullscreen {
	position: fixed;
	top: 4rem;
	left: 22rem;
	z-index: 2;
	width: 4rem;
	height: 4rem;
	background: rgba(#fff, 0.1);
	border: none;
	outline: none;
	padding: 1rem;
	transition: 0.3s ease opacity, 0.3s ease transform;

	&.hidden {
		opacity: 0;
		transform: translateY(-1.5rem);
	}

	&:hover,
	&:focus {
		background: rgba(#fff, 0.2);
	}

	&:active {
		transform-origin: center;
		transform: scale(0.9);
	}

	img {
		width: 2rem;
		height: 2rem;
		filter: brightness(0) invert(1);
	}
}

// Page Wrapper
.page-wrapper {
	padding: 3rem 0;
	height: 100vh;
	background: url('./assets/backgrounds/1.jpg') center no-repeat;
	background-size: cover;
	overflow: hidden;

	@media (max-width: 767.99px) {
		padding: 1rem 0;
	}

	&:before {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		background: #1a1d43;
		content: ' ';
		z-index: 0;
		opacity: 0.9;
	}

	.custom-container {
		position: relative;
		z-index: 1;
		width: calc(100% - 6rem);
		margin: auto;

		@media (max-width: 767px) {
			width: 90%;
		}
	}
}

// Logo
.logo {
	height: 5rem;
	filter: brightness(0) invert(1);

	@media (max-width: 767px) {
		height: 3rem;
	}
}

// Password Field
.password-field {
	label {
		font-size: 1.25rem;
		font-weight: bold;
		display: block;
		margin: 2.5rem 0 1.5rem 0;
		color: #fff;
	}

	.form-control {
		padding: 1rem;
		height: auto;
		background: rgba(#fff, 1);
		color: #16193A;
		font-size: 1.25rem;
		font-weight: bold;
		border: none;
		box-shadow: none;
		margin-right: 0.5rem;
	}

	.btn.btn-primary {
		font-weight: bold;
		background: #F37021;
		border-color: #F37021;
		box-shadow: none;
		outline: none;
		text-transform: uppercase;

		&:hover,
		&:focus {
			background: #144069;
			border-color: #144069;
		}
		&:active {
			background: #1c5a94;
			border-color: #1c5a94;
		}

		.spinner-border {
			width: 1rem;
			height: 1rem;
			border-width: 2px;
			border-color: rgba(#fff, 0.5);
			border-right-color: transparent;
		}
	}
}
</style>
