<template>
	<div :class="{'investor-details': true, 'main-investor': investment.main}">
		<h1>{{ investment.firstName ?? "&nbsp;" }} {{ investment.lastName ?? " " }}</h1>
		<div v-if="investment.state" class="d-inline d-md-flex align-items-center">
			<span>from</span>
			<h3>{{ investment.state ? getStateByShortCode(investment.state) : " " }}</h3>
		</div>
		<span>has invested</span>
		<h2>${{ investment.amount.toLocaleString() }}</h2>
		<p class="small">{{ createdAt }}</p>
	</div>
</template>

<script>
import moment from "moment";

export default {
    name: "InvestmentBox",
	props: ['investment', 'main'],
	methods: {
        getStateByShortCode(code) {
            if(code === 'AL') return 'Alabama';
            if(code === 'AK') return 'Alaska';
            if(code === 'AZ') return 'Arizona';
            if(code === 'AR') return 'Arkansas';
            if(code === 'CA') return 'California';
            if(code === 'CO') return 'Colorado';
            if(code === 'CT') return 'Connecticut';
            if(code === 'DE') return 'Delaware';
            if(code === 'DC') return 'District Of Columbia';
            if(code === 'FL') return 'Florida';
            if(code === 'GA') return 'Georgia';
            if(code === 'HI') return 'Hawaii';
            if(code === 'ID') return 'Idaho';
            if(code === 'IL') return 'Illinois';
            if(code === 'IN') return 'Indiana';
            if(code === 'IA') return 'Iowa';
            if(code === 'KS') return 'Kansas';
            if(code === 'KY') return 'Kentucky';
            if(code === 'LA') return 'Louisiana';
            if(code === 'ME') return 'Maine';
            if(code === 'MD') return 'Maryland';
            if(code === 'MA') return 'Massachusetts';
            if(code === 'MI') return 'Michigan';
            if(code === 'MN') return 'Minnesota';
            if(code === 'MS') return 'Mississippi';
            if(code === 'MO') return 'Missouri';
            if(code === 'MT') return 'Montana';
            if(code === 'NE') return 'Nebraska';
            if(code === 'NV') return 'Nevada';
            if(code === 'NH') return 'New Hampshire';
            if(code === 'NJ') return 'New Jersey';
            if(code === 'NM') return 'New Mexico';
            if(code === 'NY') return 'New York';
            if(code === 'NC') return 'North Carolina';
            if(code === 'ND') return 'North Dakota';
            if(code === 'OH') return 'Ohio';
            if(code === 'OK') return 'Oklahoma';
            if(code === 'OR') return 'Oregon';
            if(code === 'PA') return 'Pennsylvania';
            if(code === 'RI') return 'Rhode Island';
            if(code === 'SC') return 'South Carolina';
            if(code === 'SD') return 'South Dakota';
            if(code === 'TN') return 'Tennessee';
            if(code === 'TX') return 'Texas';
            if(code === 'UT') return 'Utah';
            if(code === 'VT') return 'Vermont';
            if(code === 'VA') return 'Virginia';
            if(code === 'WA') return 'Washington';
            if(code === 'WV') return 'West Virginia';
            if(code === 'WI') return 'Wisconsin';
            if(code === 'WY') return 'Wyoming';
            return code;
        },
	},
	computed: {
        createdAt() {
            if (this.investment.updatedAt === null) return "Error..";
            let today = moment();
            let accessedDate = moment(this.investment.updatedAt);
            let difference = moment.duration(today.diff(accessedDate));
            let returnString = "";
            if (difference.months() === 1) returnString += difference.months() + " month";
            else if (difference.months() > 1) returnString += difference.months() + " months";
            else if (difference.days() === 1) returnString += difference.days() + " day";
            else if (difference.days() > 1) returnString += difference.days() + " days";
            else if (difference.hours() === 1) returnString += difference.hours() + " hour";
            else if (difference.hours() > 1) returnString += difference.hours() + " hours";
            else if (difference.minutes() === 1) returnString += difference.minutes() + " minute";
            else if (difference.minutes() > 1) returnString += difference.minutes() + " minutes";
            else if (difference.seconds() === 1) returnString += difference.seconds() + " second";
            else if (difference.seconds() > 1) returnString += difference.seconds() + " seconds";
            return returnString + " ago";
        },
	},
}
</script>

<style lang="scss">

// Investor Details
.investor-details {
	margin: 0.25rem 0;
	transition: 0.5s ease all;
	display: flex;
	align-items: center;
	padding: 1.4rem 1.5rem;
	background: rgba(#16193A, 0.75);
	backdrop-filter: blur(2px);
	border-radius: 0.5rem;
	font-size: 1.5rem;

	@media (max-width: 767px) {
		display: block;
		font-size: 1rem;
	}

	h1 {
		font-weight: bold;
		font-size: 1em;
		margin: 0;
		display: block;
		line-height: 1.25;
		color: #fff;

		@media (max-width: 767px) {
			font-size: 1.25em;
			display: inline;
		}
	}

	h3 {
		font-weight: bold;
		font-size: 1em;
		margin: 0;
		display: block;
		line-height: 1.25;
		color: #fff;

		@media (max-width: 767px) {
			font-size: 1.25em;
			display: inline;
		}
	}

	span {
		font-size: 1em;
		font-weight: 400;
		display: block;
		line-height: 1.25;
		color: #fff;
		margin: 0 0.25em;

		@media (max-width: 767px) {
			font-size: 1.25em;
			display: inline;
		}
	}

	h2 {
		color: #F37021;
		font-weight: bold;
		font-size: 1em;
		margin: 0;
		transition: 0.5s ease all;
		line-height: 1.25;

		@media (max-width: 767px) {
			display: inline;
			font-size: 1.25em;
			letter-spacing: 0;
		}
	}

	p.small {
		font-size: 0.75em;
		line-height: 1.25;
		color: rgba(#fff, 0.35);
		margin: 0 0 0 auto;

		@media (max-width: 767px) {
			width: 100%;
			margin: 0.25rem 0 0 0;
			font-size: 1em;
		}
	}

	&.main-investor {
		background: #fff;
		font-size: 2rem;
		padding: 1.5rem 1.5rem;

		@media (max-width: 767px) {
			font-size: 1.25rem;
		}

		h1 {
			color: #16193A;
		}

		h3 {
			color: #16193A;
		}

		span {
			color: #16193A;
		}

		p.small {
			color: rgba(#16193A, 0.5);
		}
	}
}

</style>